body {
  overscroll-behavior-y: contain;
}

body::-webkit-scrollbar {
  display: none;
}

.pulltorefresh {
  overflow-y: hidden;
}
